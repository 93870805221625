<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title>{{ entityNamePlural }}</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-spacer></v-spacer>
    {{ filters }}
    <v-text-field
      v-model="q"
      :append-icon="$icons.mdiMagnify"
      label="Search"
      single-line
      hide-details
      clearable
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-dialog
      v-if="canCreate || canEdit"
      v-model="dialog"
      :key="editedItem.id"
      @click:outside="$emit('close')"
      max-width="800px"
    >
      <template v-if="canCreate" v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
        >
          New {{ entityName }}
        </v-btn>
      </template>
      <v-card tag="form" @submit.prevent="$emit('save', editedItem)">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <slot name="modalForm" :item="editedItem"></slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            v-if="editedItem && canDestroy"
            @click.prevent="$emit('destroy', editedItem)"
          >
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!noSave"
            color="blue darken-1"
            text
            type="submit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<script>
export default {
  props: [
    "formTitle",
    "item",
    "entityName",
    "entityNamePlural",
    "canCreate",
    "canDestroy",
    "canEdit",
    "query",
    "filters",
    "noSave",
    "prepareItem",
  ],
  data () {
    return {
      editedItem: {...this.item},
      dialog: false,
      q: this.query,
    }
  },
  watch:{
    q (v) {
      this.$emit('update:query', v)
    }
  }
}
</script>